interface MenuItem {
  label: string;
  link?: string;
}

export const menuItems: Array<MenuItem> = [
  {
    label: 'Dashboard',
    link: '/dashboard',
  },
  {
    label: 'Questions',
    link: '/questions',
  },
  {
    label: 'Quizzes',
    link: '/quizzes',
  },
];
