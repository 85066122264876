



















































import { User } from '@/models/User';
import Vue from 'vue';
import Component from 'vue-class-component';
import { menuItems } from './menuItems';

@Component({})
export default class LayoutMain extends Vue {
  private menuItems = menuItems;
  getLogoUrl(): string {
    return require('@/assets/logo-blue.png');
  }

  async logout(): Promise<void> {
    await User.logout();
    this.$router.push({ name: 'login' });
  }
}
